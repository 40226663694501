@import "../../../../../../assets/stylesheets/variables";

.table-cell {
  font-size: 15.3px !important;
  font-weight: 300 !important;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.43 !important;
  min-width: 100px !important;

  &:first-child {
    text-decoration: none !important;
    text-align: center !important;
    cursor: default !important;
  }
}

.customerTable th {
  padding-right: 0px;
  padding-left: 0px;
}
