@import "../../../../assets/stylesheets/variables";

div.container {
  display: flex;
  padding: 3em 2em;
  flex-direction: column;
}

.buttonText {
  font-size: 13.8px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 0.2em 1em;
}

.table {
  padding-top: 4em;
}

.tableCell {
  &:last-child {
    min-width: auto;
  }
}
