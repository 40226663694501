@import "../../assets/stylesheets/variables";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
}

.name {
  font-size: 15px;
  padding-left: 14px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.label {
  color: $user-input;
  font-size: 14px;
  display: inline-block;
  text-transform: capitalize;
  padding-left: 10px;
}

.icon {
  border: 1px solid $ui-secondary;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 50%;
    height: 50%;
  }
}
