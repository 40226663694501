@import "../../assets/stylesheets/variables";
div.input {
  input {
    height: 61px;
  }
  fieldset {
    border-color: $login-border !important;
  }
}

.adornment-wrapper {
  height: 61px;
  border-color: $login-border;
}

.login-icon {
  border: 1px solid $ui-primary;
  height: 33px;
  width: 33px;
  svg {
    fill: $pagination-disabled;
  }
}
