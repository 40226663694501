@import "../../assets/stylesheets/variables";

.container {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progressContainer {
  width: 65%;
  display: flex;
  justify-content: space-between;
}

.progressBar {
  background-color: $table-border;
  height: 4px;
  width: 32%;
  display: inline-block;
  border-radius: 5px;
  transition: background-color 0.2s, visibility 0.1s;
}

.weakPassword {
  background-color: $weak_password;
}

.goodPassword {
  background-color: $good_password;
}

.strongPassword {
  background-color: $strong_password;
}

.label {
  font-size: 11px;
  color: $user-input;
}
