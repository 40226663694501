@import "../../assets/stylesheets/variables";

.container {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
}

div.inputStyles {
  input {
    height: 40px;
    padding-left: 16px;
    padding-right: 22px;
  }
}

.action-button {
  position: absolute;
  top: 5px;
  right: -1em;
}

.smallInput {
  top: 0;
}
