@import "../../../../assets/stylesheets/variables";

.info-value {
  font-size: 15px;
  padding: 14px 8px 0;
  color: $black;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.info-label {
  color: $user-input;
  font-size: 15px;
  text-transform: capitalize;
  padding: 14px 8px 0;
}

.head-text {
  font-size: 18px;
  color: $header-text;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: normal;
  font-weight: 100;
  padding-bottom: 1em;
}
