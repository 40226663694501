@import "../../../../assets/stylesheets/variables";

.inputTitle {
  color: $filter;
  font-size: 15px;
  padding: 10px 0;
}

hr.separator {
  margin: 44px 0;
}

.selectContainer {
  width: 120px;
  height: 30px;
}

div.selectStyles {
  box-shadow: 0 0 5px 0 #f4f4f4;
  div {
    height: 30px;
  }
}

.autoSuspendContainer {
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-left: 47px;
}

.selectTitle {
  margin-right: 10px;
}

.ipList {
  width: 60%;
}

.section {
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 4% 0;
  justify-self: flex-end;
}

.icon {
  width: 21px;
  height: 21px;
}
