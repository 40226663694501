@import "../../../../assets/stylesheets/variables";
p.date {
  color: $black;
  text-transform: uppercase;
  font-size: 14px;
  line-height: normal;
  font-weight: 300;
  letter-spacing: normal;
  padding: 30px 0;

  &:first-child {
    padding-top: 15px;
  }
}

.itemsContainer {
  max-width: 85%;
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: space-between;
}
