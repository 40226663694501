@import "../../assets/stylesheets/variables";

div.container {
  padding-top: 67px;
  @include extraLarge {
    padding-top: 0;
  }
  @include large {
    display: flex;
  }
}

header.app-bar {
  background-color: $sidebar-background;
  z-index: 1301;
}

button.button {
  padding: 5px;
  svg {
    width: 57px;
    height: 57px;
  }
}

.main {
  @include small {
    width: 100%;
  }
  @include extraLarge {
    width: 1024px;
  }
}
