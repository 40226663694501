@import "../../../../assets/stylesheets/variables.scss";

p.country {
  font-size: 15px;
}

div.postcodeInput {
  input {
    @include ie10 {
      width: 20%;
    }
  }
}
