label.root {
  margin-left: 0;
  margin-right: 0;
}

span.label {
  font-size: 15px;
  letter-spacing: normal;
}

button.info {
  padding: 0;
  margin-left: 0.5em;
  &:hover {
    background-color: transparent;
  }
}

.tooltipPlacementTop {
  top: -12px;
}

.item {
  padding: 14px 0;
}
