@import "../../../assets/stylesheets/variables";

.contentWrapper {
  padding: 5% 0;

  @include screenResolution1x5 {
    padding: 4%;
  }
}

.companyDetails {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 3%;
}

.name {
  font-size: 16px;
}

.detailAccount {
  width: 40%;
}

.detailName {
  width: 60%;
}
