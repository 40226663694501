@import "../../../assets/stylesheets/variables";

div.filters {
  margin-top: 22px;
  margin-bottom: 12px;
  width: 70%;
  justify-content: space-between;
  @include large {
    width: 50%;
  }
}
