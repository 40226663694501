@import "../../assets/stylesheets/variables";

.wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $loader;
  z-index: 1500;
  display: flex;
  justify-content: center;
  .image {
    align-self: center;
    width: 50px;
    height: 50px;
  }

  @include large {
    .image {
      width: 120px;
      height: 120px;
    }
  }
}
