@import "../../../../assets/stylesheets/variables";

.container {
  a {
    text-transform: uppercase;
    position: absolute;
    bottom: 50px;
    left: 38px;
    font-size: 16px;
    color: $ui-secondary-dark;
    &:before {
      content: "";
      background: url("../../../../assets/icons/back.svg") no-repeat;
      width: 14px;
      height: 22px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}

.title {
  margin-bottom: 5%;
}

.button {
  margin-top: 14%;
}
