@import "../../../../assets/stylesheets/variables";

.wrapper {
  height: 360px;
  min-height: 360px;
}

div.container {
  flex-direction: row;
  justify-content: space-between;
}

div.field {
  width: 48%;
  padding-bottom: 23px;
}

div.password {
  width: 48%;
}

div.newPassword {
  width: 94%;
}

span.switchLabel {
  font-size: 14px;
  color: $user-input;
  font-weight: normal;
}

div.emailAsLoginContainer {
  width: 48%;
  padding-left: 14px;
}

div.emailAsLogin {
  padding: 12px 0;
  margin-bottom: 36px;
}

div.defaultPassword {
  margin-top: 27px;
}

label.checkbox-label {
  margin-right: 0;
  margin-left: 0;
  span {
    padding: 0;
  }
}

.passwordIndicator {
  width: 94%;
  padding-top: 10px;
  padding-left: 5px;
  height: 36px;
}
.adornmentIcon {
  svg {
    width: 60%;
    height: 60%;
  }
}
