@import "../../../../../../assets/stylesheets/variables";

td.field_version {
  width: 15%;
  min-width: 20px;
  word-break: normal;
}

td.field_status {
  width: 45%;
  min-width: 20px;
  word-break: normal;
}

td.field_createdAt {
  width: 20%;
  min-width: 20px;
  word-break: normal;
}

td.field_usedTotal {
  width: 10%;
  min-width: 20px;
  word-break: normal;
}

td.field_expectedTotal {
  width: 10%;
  min-width: 20px;
  word-break: normal;
}

.select-item {
  padding-left: 14px;
}
