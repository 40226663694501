/* stylelint-disable function-parentheses-space-inside */
$black: black;
$white: white;
$page-background-color: $white;
$ui-primary: #d43331;
$ui-local-primary: #009adf;
$ui-secondary: #bebebe;
$main-font: #414143;
$button-gradient: linear-gradient(120deg, #da0032, #aa0034);
$button-local-gradient: linear-gradient(120deg, #159bdf, #0a61a2);
$button-secondary-gradient: linear-gradient(to bottom, #79848d, #4b5761);
$sidebar-background: #282828;
$border: #979797;
$table-border: #ededed;
$pagination-disabled: #808285;
$filter: #808080;
$expansion-panel: #bfbfbf;
$user-input: #929699;
$warning: #dc0032;
$header-text: #878787;
$shadow: #f2f3f5;
$transfer-add-item: #f2f2f2;
$table-inner-border: #ccc;
$login-border: #e0e3e5;
$or-separator: #e7e7e7;
$weak_password: #ff4b47;
$good_password: #f9ae35;
$strong_password: #2daf7d;
$input-shadow: #e0e0e0;
$loader: rgba(192, 192, 192, 0.7);
$disable-opacity: 0.7;

$ui-primary-dark: darken($ui-primary, 10%);
$ui-primary-light: #d4333126;
$ui-local-primary-dark: darken($ui-local-primary, 10%);
$ui-local-primary-light: #009adf26;
$ui-secondary-dark: darken($ui-secondary, 10%);
$ui-secondary-light: lighten($ui-secondary, 10%);

$colors: () !default;
$colors: map-merge(
  (
    "white": $white,
    "uiPrimary": $ui-primary,
    "uiPrimaryDark": $ui-primary-dark,
    "uiPrimaryLight": $ui-primary-light,
    "uiLocalPrimary": $ui-local-primary,
    "uiLocalPrimaryDark": $ui-local-primary-dark,
    "uiLocalPrimaryLight": $ui-local-primary-light,
    "uiSecondary": $ui-secondary,
    "uiSecondaryDark": $ui-secondary-dark,
    "uiSecondaryLight": $warning,
    "uiLocalSecondaryLight": $ui-local-primary,
    "buttonGradient": $button-gradient,
    "buttonLocalGradient": $button-local-gradient,
    "buttonSecondaryGradient": $button-secondary-gradient,
    "paginationDisabled": $pagination-disabled,
    "tableBorder": $table-border,
  ),
  $colors
);

:export {
  white: $white;
  uiPrimary: $ui-primary;
  uiPrimaryDark: $ui-primary-dark;
  uiPrimaryLight: $ui-primary-light;
  uiLocalPrimary: $ui-local-primary;
  uiLocalPrimaryDark: $ui-local-primary-dark;
  uiLocalPrimaryLight: $ui-local-primary-light;
  uiSecondary: $ui-secondary;
  uiSecondaryDark: $ui-secondary-dark;
  uiSecondaryLight: $warning;
  uiLocalSecondaryLight: $ui-local-primary;
  buttonGradient: $button-gradient;
  buttonLocalGradient: $button-local-gradient;
  buttonSecondaryGradient: $button-secondary-gradient;
  paginationDisabled: $pagination-disabled;
  tableBorder: $table-border;
}
