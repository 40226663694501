@import "../../../assets/stylesheets/variables";

.table {
  min-width: 100%;
}

span.all-checkbox {
  margin-right: 25px;
}

.small-cell {
  @include large {
    width: 30px;
  }
}

.pagination {
  margin-top: 20px;
  & > div {
    flex-direction: row-reverse;
    padding: 0;
  }
}

div.paginationSpacer {
  flex-basis: 0;
}

table > tbody:focus {
  border: none;
  outline: none;
}

.tool-bar {
  margin-bottom: 7px;
}

.checkbox-hidden {
  visibility: hidden;
}

.counter {
  font-size: 12px;
  color: $black;
  opacity: 0.5;
}

.suspended-icon {
  width: 20px;
  height: 19px;
}

.contentContainer {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: space-between;
}
