@import "../../../../assets/stylesheets/variables";

.select {
  width: 55%;
}

.spacing {
  padding-bottom: 2%;
}

.wrapper {
  padding-top: 2%;
}

.label {
  width: 44%;
}
