@import "../../../../assets/stylesheets/variables";

ul li.item {
  min-height: 0;
}

p.warnText {
  padding-left: 2%;
}

p.subItem {
  font-weight: 300;
  padding-left: 8%;
  line-height: 1.77 !important;
}
p.withPadding {
  padding: 4px 0;
}

.dropdown {
  min-width: 220px;
  text-align: center;
}

div.withoutPadding {
  padding-right: 0 !important;
}

.tooltipPlacementTop {
  top: -10px;
}

div.selectContainer {
  width: 50%;
}
