@import "../../../assets/stylesheets/variables";

p.text {
  font-size: 15.3px;
  color: $black;
}

div.content {
  margin-bottom: 10px;
  padding: 0;
}

li.listItemWrapper {
  padding-left: 0;
  padding-right: 0;
}

div.listItem {
  min-width: 20%;

  span {
    font-size: 18px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: normal;
  }
}

div.listItemSLID {
  flex: 0 0 20%;
}
div.listItemAccounts {
  flex: 0 0 80%;
}

div.listContainer {
  display: flex;
  width: 100%;
}
