@import "../../assets/stylesheets/variables";

div.containerOne {
  .item {
    &:first-child {
      margin-right: 8%;
    }
    &:nth-child(2) {
      margin-right: 7%;
    }

    @include screenResolution1x5 {
      &:first-child {
        margin-right: 5%;
      }
      &:nth-child(2) {
        margin-right: 4%;
      }
    }
  }
}

div.containerTwo {
  .item {
    &:first-child {
      margin-right: 5%;
    }
    &:nth-child(2) {
      margin-right: 4%;
    }
    @include screenResolution1x5 {
      &:first-child {
        margin-right: 2%;
      }
      &:nth-child(2) {
        margin-right: 1%;
      }
    }
  }
}

span.custom-label {
  font-size: 18px;
  opacity: 0.5;
}

.error {
  font-size: 13px;
  line-height: 1.54;
  letter-spacing: 0.03px;
  color: #dc0032;
}

div.systemAccessContainer {
  margin-top: 34px;
  margin-bottom: 28px;
}

div.token {
  width: calc(100% - 180px);
  word-break: break-all;
  padding-left: 25px;
  align-items: center;
  display: flex;
}

button.generateToken {
  padding: 12px;
  width: 160px;
  font-size: 12.5px;
  line-height: normal;
  white-space: nowrap;
}

button.generateToken:disabled {
  background: none;
  background-color: $ui-secondary !important;
  span {
    color: $white;
  }
}
