@import "../../assets/stylesheets/variables";

.rounded {
  border: 1px solid $table-border;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 51px;
}

.icon {
  font-size: 2.2rem !important;
  top: calc(50% - 18px) !important;
  color: $user-input !important;
}

div.select {
  height: 51px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  &:focus {
    background-color: transparent !important;
  }
}

div.root {
  flex: 1 1 auto;
}

.disabled {
  opacity: $disable-opacity;
}

div.paper {
  box-shadow: none;
  border: 1px solid $table-border;
  max-height: 275px;
}

.meta-error {
  border-color: $warning;
  div {
    border-color: $warning;
  }
}
