@import "../../assets/stylesheets/variables";

td.cell {
  border-right: solid 0.5px $table-inner-border;
  border-bottom: none;
  font-size: 15.3px;
  font-weight: 300;
  letter-spacing: normal;
  color: $black;
  text-align: center;
  padding: 7px 20px;
  text-transform: uppercase;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  min-width: 160px;
  &:first-child {
    text-decoration: underline;
    font-weight: normal;
    text-align: start;
    cursor: pointer;
    a {
      color: $black;
    }
  }
  &:last-child {
    border-bottom: none;
    border-right: none;
  }
}

th.headerCell {
  border-bottom: solid 1px $table-inner-border;
  font-size: 15.3px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  color: $header-text;
  padding-right: 30px;
  padding-left: 30px;
  background-color: $page-background-color;
}

tbody:before {
  line-height: 1em;
  display: block;
}
