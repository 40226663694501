@import "../../../assets/stylesheets/variables";

.expand-collapse {
  margin-top: 40%;
  margin-bottom: 20%;
  @include screenResolution1x5 {
    margin-top: 4%;
    margin-bottom: 2%;
  }
}
