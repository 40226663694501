@import "../../assets/stylesheets/variables";

.text {
  font-size: 18px;
  color: $header-text;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 100;
}

.email {
  font-size: 14px;
  color: rgba(92, 177, 25, 1);
  text-transform: uppercase;
}
