.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 7%;
}

.pickerContainer {
  width: 30%;
}

.logItemsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.pagination {
  margin-top: 20px;
  & > div {
    flex-direction: row-reverse;
    padding: 0;
  }
}
