@import "sizes";

@mixin small() {
  @media screen and (min-width: $min-small-resolution) {
    @content;
  }
}

@mixin medium() {
  @media screen and (min-width: $min-medium-resolution) {
    @content;
  }
}

@mixin large() {
  @media screen and (min-width: $min-large-resolution) {
    @content;
  }
}

@mixin extraLarge() {
  @media screen and (min-width: $min-extra-large-resolution) {
    @content;
  }
}

@mixin maxLarge() {
  @media screen and (max-width: $max-large-screen-width) {
    @content;
  }
}

@mixin ie10() {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}

@mixin screenResolution1x5() {
  @media screen and (resolution: 1.5dppx) {
    @content;
  }
}
