@import "../../../../assets/stylesheets/variables";

.pagination {
  margin-top: 20px;
  & > div {
    flex-direction: row-reverse;
    padding: 0;
  }
}

div.paginationSpacer {
  flex-basis: 0;
}

.wrapper {
  padding: 10% 5%;
  @include medium {
    padding: 3% 6%;
  }
  @include large {
    padding-left: 43px;
    padding-right: 37px;
  }
  @include extraLarge {
    padding-top: 47px;
    padding-bottom: 58px;
    padding-left: 93px;
    padding-right: 77px;
  }
}

div.container {
  display: flex;
  padding: 30px 30px;
  flex-direction: column;
  align-items: flex-start;
}

div.inputContainer {
  margin-top: 5%;
  @include small {
    flex-direction: column;
  }
  @include medium {
    flex-direction: row;
    justify-content: space-between;
  }
}
div.input {
  width: 80%;
  padding-bottom: 2%;
  @include large {
    width: 48%;
  }
}

.counter {
  font-size: 12px;
  color: $black;
  opacity: 0.5;
}

.searchContent {
  justify-content: flex-end;
}

.content {
  button {
    margin-top: 10%;
  }
  table {
    margin-top: 3%;
  }
}

.buttonText {
  padding: 5px 30px;
}

.row-hover {
  &:hover {
    .tools {
      visibility: visible;
    }
  }
}

tr.rowStyles.row-selected {
  .tools {
    visibility: visible;
  }
}

.rowStyles {
  opacity: 1;

  .tools {
    visibility: hidden;
    button {
      margin-top: 0;
    }
  }

  td {
    &:last-child {
      min-width: auto;
      width: 12%;
      padding-right: 0;
      padding-left: 10px;
    }
  }
}

.disabled {
  opacity: 0.4;
}

p.warning {
  color: $warning;
  font-size: 15px;
  line-height: normal;
  text-align: center;
}

div.warningContainer {
  width: 100%;
  padding-top: 25px;
}

div.buttonContainer {
  padding-top: 24px;
}

.link {
  display: flex;
  align-items: center;
  width: calc(100% - 39px);
  padding-left: 5px;

  a {
    word-break: break-all;
  }
}

.text {
  display: flex;
  align-items: center;
  width: calc(100% - 39px);
  word-break: break-all;
  cursor: initial;
  padding-left: 5px;
}
