@import "../../assets/stylesheets/variables";

div.content {
  &.expandedTab {
    margin: 12px 0;
  }
}

div.panel {
  box-shadow: none;

  &.panelExpanded {
    margin: 0;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 43px;
    }
  }
  margin-bottom: 43px;
  width: 100%;
  div.root {
    background-color: $expansion-panel;
    min-height: 43px;
    max-height: 43px;
  }
}

div.expandIcon {
  background: none;
}

.header {
  color: $white;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

div.details {
  display: block;
  padding: 31px 28px 16px 19px;
}

div.collapsedContainer {
  position: relative;
}

span.badge {
  border: 1px solid $white;
}
