@import "../../assets/stylesheets/variables";

button.button {
  @include medium {
    width: 172px;
    padding: 14px 0;
    border-radius: 5px;
    font-size: 15px;
  }
  &:disabled {
    background: $ui-secondary;
    color: $white;
  }
}
