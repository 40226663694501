div.container {
  width: 100%;
}

div.switch-container {
  width: 100%;
  margin-bottom: 4%;
  flex: 50%;
  height: 100%;
}

div.items-container {
  width: 100%;
  padding-bottom: 2%;
}
