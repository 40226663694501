@import "../../assets/stylesheets/variables";
.container {
  margin-left: 10px;
}

.top {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid $white;
  opacity: 0.3;
  margin-bottom: 2px;
}

.bottom {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;

  border-top: 6px solid $white;
  opacity: 0.3;
}

.active {
  border-top-color: $white;
  opacity: 1;
}
