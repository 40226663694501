@import "../../assets/stylesheets/variables";

div.input {
  width: 45%;
  padding-right: 4%;
}

button.button {
  &:hover {
    background-color: transparent;
  }
}
.button-label {
  font-size: 11px;
  font-weight: normal;
  letter-spacing: 0.2px;
}
