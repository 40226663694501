@import "../../../assets/stylesheets/variables";

a.item {
  color: $filter;
  width: fit-content;
  border-bottom: 2px solid $white;
  margin-left: 0;
  padding-bottom: 0;
  padding-left: 7px;
  padding-right: 7px;

  &:hover {
    background-color: transparent;
    cursor: pointer;
  }
}
