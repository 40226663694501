@import "../../../../../assets/stylesheets/variables";

.inputTitle {
  color: $filter;
  font-size: 15px;
}

.inputTitleMargin {
  margin-bottom: 14px;
}

div.inputStyles {
  input {
    height: 40px;
    padding-left: 16px;
    padding-right: 22px;
  }
}
