@import "../../assets/stylesheets/variables";

div.content {
  padding-right: 15%;
}

.wrapper {
  @include screenResolution1x5 {
    padding-right: 2%;
  }
}
