@import "../../../../assets/stylesheets/variables";

label.checkbox {
  margin-right: 0;
}

span.label {
  font-size: 15px;
  line-height: normal;
  white-space: pre-wrap;
}

p.warning {
  color: $warning;
  font-size: 15px;
  line-height: normal;
}

p.default-text {
  font-size: 14px;
  line-height: 1.4;
  color: $user-input;
  font-weight: normal;
  white-space: pre-wrap;
  text-align: center;
}

.spacing {
  padding-bottom: 15px;
}

div.default {
  padding-bottom: 4%;
  width: 70%;
}

div.tabs-section {
  padding-top: 4%;
  min-height: 32em;
  div {
    header {
      div {
        div {
          overflow-x: auto;
        }
      }
    }
  }
}

.default-information {
  width: 75%;
}

div.weight {
  width: 40%;
}

svg.icon {
  height: 18px;
  width: 16px;
}
