@import "../../../../assets/stylesheets/variables";

hr.verticalDivider {
  height: 271px;
}

.addIpContainer {
  display: flex;
  justify-content: space-between;
}

.addIp {
  width: 44%;
}
