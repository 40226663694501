@import "../../assets/stylesheets/variables";
.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.title {
  font-size: 30px;
  letter-spacing: 1px;
  color: $black;
}

.subtitle {
  font-size: 20px;
  color: $warning;
  padding-top: 3%;
}

.logo {
  width: 180px;
}

.logoContainer {
  width: 90%;
}

.textContainer {
  text-align: center;
}

button.button {
  font-size: 14px;
  letter-spacing: 1px;
  width: 120px;
  border-radius: 6px;
  padding: 10px 0;
  line-height: normal;
}

.image {
  width: 228px;
}
