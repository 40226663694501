@import "../../../../assets/stylesheets/variables";

.form {
  padding: 0 2%;
}

div.formField {
  margin: 4% 0 1%;
}

.actions {
  align-items: center;
  margin-top: 10%;
  .button {
    padding-left: 4%;
  }
  a {
    color: $user-input;
    cursor: pointer;
    font-size: 14px;
    margin-right: 36px;
  }
}
