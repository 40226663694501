@import "../../assets/stylesheets/variables";

button.button {
  padding: 8px;
}

.icon {
  width: 10px;
  height: 10px;
}

div.container {
  position: absolute;
  right: 1px;
  top: 12px;
  background-color: $white;
  height: auto;
  max-height: none;
  margin-left: 0;
  padding: 0 2px;
}
