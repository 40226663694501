@import "../../assets/stylesheets/variables";

.container {
  width: 100%;
  background: $button-gradient;
  padding: 11px 0 11px 25px;
  span {
    color: $white;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}
