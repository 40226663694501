@import "../../assets/stylesheets/variables";

span.label {
  font-size: 14px;
  letter-spacing: normal;
  font-weight: normal;
  line-height: normal;
}

p.title {
  font-size: 15px;
  line-height: normal;
  font-weight: normal;
  opacity: 0.5;
  text-transform: uppercase;
  letter-spacing: normal;
  padding: 6px 0;
  @include large {
    width: 30%;
  }
}

div.button-container {
  margin-top: 40px;
  flex-wrap: wrap;
}

div.buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @include large {
    width: 70%;
  }
}
