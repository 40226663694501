@import "../../../../assets/stylesheets/variables";

td.role {
  line-height: 0.9;
}

.table-row {
  td {
    border-bottom: 1px solid $table-border;
    height: 84px;
    font-size: 16px;
    word-break: break-all;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .tools-visibility {
    visibility: hidden;
  }
  .name-cell {
    width: 20%;
  }

  .login-cell {
    width: 38%;
  }
  &:hover {
    .tools-visibility {
      visibility: visible;
    }
  }
}

.expired {
  font-size: 9px;
  color: $black;
  opacity: 0.5;
  text-transform: uppercase;
  padding-top: 5px;
}

.suspended {
  opacity: 0.4;
}

.admin-checkbox {
  visibility: hidden;
}

.suspended-text {
  opacity: 1;
}

td.tools {
  min-width: 125px;
  @include large {
    text-align: right;
  }
}

.iconContainer {
  position: relative;
  .icon {
    position: absolute;
    top: 40%;
    right: 0;
  }
}
