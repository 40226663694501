@import "../../assets/stylesheets/variables";

.search {
  @include small {
    justify-content: space-between;
  }
  @include large {
    justify-content: flex-end;
  }
}

a.add-button {
  @include large {
    font-size: 11px;
    border-radius: 5px;
    padding: 8px 12px;
  }
}
