@import "../../assets/stylesheets/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.textContainer {
  padding-top: 5%;
  padding-bottom: 3%;
}

h4.header {
  font-size: 30px;
  color: $filter;
  text-transform: uppercase;
  line-height: 1.89;
  height: 50px;
}

p.subtitle {
  color: $filter;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 1.89;
}

.image {
  width: 33%;
  flex: 0 0 auto;
}
