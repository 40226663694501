@import "../../../../assets/stylesheets/variables";

.container {
  padding-bottom: 20px;
  .subtitle p {
    text-transform: uppercase;
    padding: 5px 0 0px 0px;
    font-size: 16px !important;
    line-height: 23px !important;
    border-bottom: 1px solid;
    margin: 0;
    font-weight: 300;
    color: $black;
    cursor: default;
  }
}

div.text {
  word-break: break-all;
}

div.title {
  font-size: 16px;
  line-height: 23px;
  text-transform: uppercase;
}

.subSections {
  padding-top: 5px;
}
