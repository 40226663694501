@import "../../../assets/stylesheets/variables";

.container {
  padding-bottom: 1em;
}

.text {
  font-size: 18px;
  color: $header-text;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 100;
  padding-bottom: 1em;
  padding-top: 1em;
}
