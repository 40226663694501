@import "../../../../assets/stylesheets/variables";

.container {
  padding-top: 8px;
}
.rangeContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5%;
}

.inputContainer {
  width: 47%;
}

.hyphen {
  align-self: flex-end;
  margin-bottom: 20px;
  width: 9px;
  height: 3px;
  background-color: $black;
}

.nameContainer {
  display: flex;
  justify-content: space-between;
}

.nameInput {
  width: 85%;
}

.button {
  align-self: flex-end;
}
