div.container p {
  padding: 6px 12px;
  margin-bottom: 30px;
  cursor: pointer;
  display: inline-block;
  border-bottom: 2px solid;
  font-weight: 100;
  color: #878787;
  line-height: 1.75;
  white-space: normal;
  text-transform: uppercase;
  user-select: none;
  font-size: 18px !important;
  letter-spacing: normal;
  word-spacing: normal;
  text-rendering: auto;
}
