@import "../../../../assets/stylesheets/variables";

.wrapper {
  width: 100%;
  display: flex;
  flex: 1;
}

.labelWrapper {
  align-items: center;
  display: flex;
  font-size: 15px;
  color: $user-input;
  max-width: 115px;
}

div.dropdownContainer {
  align-items: flex-start;
  .dropdown {
    width: 220px;
    color: $black;
    font-size: 15px;
    div {
      justify-content: flex-start;
      padding-left: 14px;
      max-height: 50px;
    }
  }
}

.menuItem {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  display: block !important;
}

ul.listContainer {
  width: 400px;
}

.listTextItem {
  overflow: hidden;
  text-overflow: ellipsis;
}
