@import "../../assets/stylesheets/variables";

.menuItem {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;
}

.menu {
  max-width: 380px;
  max-height: 14rem;
  overflow: auto;
}
