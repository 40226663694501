@import "../../assets/stylesheets/variables";

.container {
  display: flex;
  flex-direction: column;
  padding: 3% 4%;
  min-height: 100vh;
  @include large {
    padding-top: 27px;
    padding-left: 43px;
    padding-right: 37px;
  }
  @include extraLarge {
    padding-top: 47px;
    padding-left: 93px;
    padding-right: 77px;
  }
}
