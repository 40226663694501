@import "~normalize.css/normalize.css";
@import "assets/stylesheets/variables";
@import "assets/stylesheets/fonts";

:root {
  @each $color, $value in $colors {
    --#{$color}: #{$value};
  }
}

html {
  @include maxLarge {
    margin-left: 10px;
  }
  @include screenResolution1x5 {
    margin-left: 0;
  }
  margin-left: calc(100vw - 100%);
  margin-right: 0;
}

body {
  min-width: 320px;
  max-width: $max-screen-width;
  margin: 0 auto;
  font-family: $roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

*:-moz-focusring,
*:focus {
  outline: none !important;
}

section {
  max-height: 100%; // Disable accessibility text resize in Chrome
}

.hidden {
  display: none;
}

input::-ms-clear,
input::-ms-reveal {
  display: none; // Disable the little x on the input to clear content and the eye on password fields in Edge
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

svg {
  overflow: hidden; // Prevent additional empty space issue on IE
}
