@import "../../assets/stylesheets/variables";

p.text {
  font-size: 18px;
  color: $header-text;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: normal;
  font-weight: 100;
  padding-bottom: 1em;
  padding-top: 1em;
}

.required {
  color: $ui-primary;
}
