@import "../../../../assets/stylesheets/variables";

div.container {
  display: flex;
  padding: 40px 30px;
  flex-direction: column;
  align-items: flex-start;
}

div.item {
  padding-right: 14px;
  padding-bottom: 14px;
  min-width: 247px;
}

.prerelease {
  color: grey;
}

div.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 14px;
}

div.inputContainer {
  margin-top: 5%;
  @include small {
    flex-direction: column;
  }
  @include medium {
    flex-direction: row;
    justify-content: space-between;
  }
}
div.input {
  width: 80%;
  padding-bottom: 2%;
  @include large {
    width: 48%;
  }
}

.content {
  justify-content: flex-end;

  button {
    margin-top: 10%;
  }
  table {
    margin-top: 3%;
  }
}

.buttonText {
  padding: 5px 30px;
}

.row-hover {
  &:hover {
    .tools {
      visibility: visible;
    }
  }
}

.rowStyles {
  opacity: 1;

  td {
    width: 33%;
    padding-right: 0;
    padding-left: 10px;
  }
}

button.disabled {
  background: $ui-secondary;
  color: white !important;
}

p.warning {
  color: $warning;
  font-size: 15px;
  line-height: normal;
  text-align: center;
}

div.warningContainer {
  width: 100%;
  padding-top: 25px;
}

div.buttonContainer {
  padding-top: 24px;
}

.text {
  display: flex;
  align-items: center;
  width: calc(100% - 39px);
  word-break: break-all;
  cursor: initial;
  padding-left: 5px;
}

.pagination {
  margin-top: 20px;
  & > div {
    flex-direction: row-reverse;
    padding: 0;
  }
}

.counter {
  font-size: 12px;
  color: $black;
  opacity: 0.5;
}
