@import "../../../../assets/stylesheets/variables";
p.text {
  font-size: 15px;
  padding-top: 0;
  margin: 0;
  color: $header-text;
  text-transform: uppercase;
  font-weight: 100;
}

div.textArea > div > fieldset {
  height: 100px;
}

div.container {
  margin-bottom: 50px;
}

p.header-styles {
  padding: 0;
}

div.textArea > div > fieldset {
  border: none;
}

div.container {
  margin-bottom: 50px;
}

div.textArea {
  border: 1px solid $table-border;
  border-radius: 7px;
}
