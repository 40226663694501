@import "../../assets/stylesheets/variables";

div.root {
  margin-right: 4px;
}

.icon {
  width: 25px;
  height: 25px;
  fill: $ui-secondary;
}

.visiblePassword {
  .icon {
    fill: $pagination-disabled;
  }
}

.keyIcon {
  width: 70% !important;
  height: 75% !important;
}
