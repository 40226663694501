@import "../../assets/stylesheets/variables";
.container {
  justify-content: space-between;
  padding-bottom: 25px;
}

button.button {
  @include medium {
    width: 172px;
    padding: 14px 0;
    border-radius: 5px;
    font-size: 15px;
  }
}
