div.container {
  width: 98%;
}

div.profile-item {
  width: 48%;
  padding-bottom: 2%;
}

div.account-number {
  width: 100%;
  padding-bottom: 2%;
}
