@import "../../../../assets/stylesheets/variables";

.form {
  padding: 0 2%;
}

.formTitle {
  margin-bottom: 5%;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $user-input;
  white-space: pre-wrap;
}

.fields {
  padding: 0;
  margin: 10% 0;
  .icon {
    width: 15px;
    height: 15px;
  }
}

.actions {
  justify-content: flex-end;
}
