@import "../../../../assets/stylesheets/variables";

.form {
  padding: 0 2%;
}

.formTitle {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $user-input;
  margin-bottom: 5%;
}

.fields {
  margin-top: 10%;
}

div.field {
  margin: 8% 0;
}

.actions {
  margin-top: 5%;
  justify-content: flex-end;
}

.passwordMeter {
  padding-top: 10px;
  padding-left: 5px;
  height: 10px;
}
