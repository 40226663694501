div.container {
  width: 98%;
}

div.switch-container {
  width: 30%;
  margin-bottom: 2%;
}

div.items-container {
  width: 48%;
  padding-bottom: 2%;
}

.account-number {
  width: 100%;
  padding-bottom: 2%;
}
