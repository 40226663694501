@import "../../assets/stylesheets/variables";

.heightStyle {
  height: 38px !important;
}

div.select {
  height: 38px;
  padding-top: 0;
  padding-bottom: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  &:focus {
    background-color: transparent !important;
  }
}

.service {
  width: 85%;
}

td.mapping {
  width: 45%;
  text-transform: none;
  &:first-child {
    text-decoration: none;
  }
}

td.service-cell {
  text-transform: none;
  padding: 1% 3%;
}

.tableContainer {
  max-height: 24em;
  overflow-y: auto;
}

table.table {
  border-collapse: separate;
}

.fixed {
  background-color: $white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.option {
  text-transform: uppercase;
}

button.delete {
  padding: 8px;
}
