.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.buttonText {
  padding: 5px 30px;
}
