@import "../../assets/stylesheets/variables";

.container {
  width: 100%;
}

header.colorDefault {
  color: $black;
  background-color: $white;
}

.wrapper {
  font-family: $roboto;
  font-weight: 100;
  color: $header-text;
  font-size: 18px;
}

button.root {
  min-width: auto;
  margin-right: 10px;
  padding: 8px 12px;
  &:last-child {
    margin-right: 0;
  }
}

div.boxRoot {
  padding: 0;
}

span.badge {
  height: 14px;
  min-width: 14px;
  font-size: 10px;
  padding: 0;
  right: -4px;
}

div.scrollButtons {
  svg {
    fill: $warning;
  }
}
