@import "../../../../assets/stylesheets/variables";

div.sectionContainer {
  display: flex;
  justify-content: space-between;
}

div.buttonContainer {
  display: flex;
  flex-direction: column;
  width: 240px;
  align-items: flex-end;
  justify-content: space-between;
  padding: 6px 0;
}

div.content {
  padding-top: 4%;
  padding-left: 3%;
  padding-right: 2%;
}

div.tabs {
  padding-top: 5%;
  min-height: 33em;
}

button.button {
  padding: 14px 30px;
  border-radius: 5px;
  font-size: 15px;
  line-height: normal;
  min-width: 172px;
}

button.sectionButton {
  padding: 12px;
  width: 160px;
  font-size: 12.5px;
  line-height: normal;
  white-space: nowrap;
}

button.sectionButton:disabled {
  background: none;
  background-color: $ui-secondary !important;
  span {
    color: $white;
  }
}

.buttonsContainer {
  margin: 4% 0;
}
