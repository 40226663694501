@import "../../../../assets/stylesheets/variables";

.addIpContainer {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
}

.inputWrapper {
  margin-bottom: 5%;
  width: 85%;
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5%;
}

.nameInput {
  width: 85%;
}

.addButton {
  align-self: flex-end;
}

.addIp {
  width: 44%;
}
