@import "../../../../assets/stylesheets/variables";

div.buttons-container {
  width: auto;
  flex-wrap: nowrap;
  padding-right: 5px;
}

button.pagination-button {
  padding: 7px;
}

.arrow-disabled {
  circle {
    fill: $pagination-disabled;
  }
}

.previous {
  transform: rotate(180deg);
}
