@import "../../../../assets/stylesheets/variables";

.header {
  text-align: center;

  h1 {
    font-size: 24px;
    font-weight: normal;
    margin-top: 10px;
  }
}
