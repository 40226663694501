@import "../../../../assets/stylesheets/variables";

.root {
  min-height: 100vh;
  position: relative;
}
.image {
  background-image: url("../../../../assets/images/login-woman.png");
  background-repeat: no-repeat;
  background-size: cover;
  flex: 2 0 auto;
  height: 100vh;
}

.header {
  flex: 1 0 0;
  text-align: center;
  .logo {
    margin-top: 4%;
    width: 31%;
  }
  h1 {
    font-size: 24px;
    font-weight: normal;
    margin-top: 10px;
  }
}

.content {
  width: 60%;
  margin: 0 auto;
  text-align: center;
  flex: 2 0 0;
}

.gridContainer {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
