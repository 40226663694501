@import "../../../../assets/stylesheets/variables";

.container {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.wrapContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
}

hr.divider {
  height: 1px;
  margin: 5px 0 5px 40px;
}

.auditLog {
  flex: 1;
  padding-right: 10px;
}

.expandBtn {
  color: $filter;
  font-size: 15px;
  font-weight: normal;
  line-height: normal;
  cursor: pointer;
  min-width: 110px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .arrow {
    margin-left: 10px;
    g {
      fill: $filter;
    }
  }
  .upArrow {
    transform: rotate(180deg);
  }
}

div.time {
  color: $filter;
  font-size: 13px;
  font-weight: normal;
  line-height: normal;
  width: 40px;
}

div.action {
  color: $black;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  padding-left: 25px;
  max-width: 95%;
  display: flex;
  flex: 1;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 20px;
}

.logDescriptionContainer {
  margin: 0 0 5px 115px;
}
