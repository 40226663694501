@import "../../assets/stylesheets/variables";

div.container {
  padding-top: 2%;
  width: 100%;
  .profile {
    padding-bottom: 27px;
  }

  @include large {
    padding-top: 11px;
  }

  .avatar {
    width: 63px;
    height: 61px;
  }
}

.space {
  padding-left: 33px;
}

.logo {
  padding-left: 16px;
  padding-bottom: 18px;
  display: flex;
  align-items: center;
  svg {
    width: 71px;
    height: 71px;
  }

  .accountLabel {
    color: $user-input;
    font-size: 15px;
  }
}

.logout {
  padding-left: 22px;
  margin-top: 15px;
  opacity: 0.3;
  svg {
    height: 30px;
  }
}

div.switchContainer {
  min-height: 51px;
}

div.dropdownContainer {
  background-color: $sidebar-background;
  border: solid 1px $user-input;

  .dropdown {
    width: 180px;
    color: $user-input;
    font-size: 15px;
  }
}

div.dropdownMenu {
  background-color: $sidebar-background;
  border: solid 1px $user-input;
  color: $user-input;
  margin-top: -1px;

  ul li:hover {
    background-color: #444444;
  }

  .selectedItem,
  .selectedItem:hover {
    background-color: #3c3c3c;
  }
}
