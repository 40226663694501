@import "../../assets/stylesheets/variables";

.wrapper {
  width: 100%;
  position: relative;
}

.controls {
  top: 0;
  right: 12%;
  position: absolute;
}

input.input {
  color: $user-input;
  font-size: 14px;
  padding: 6px 0;
  width: auto;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: auto;
  }
}

button.date-button {
  padding: 6px;
  svg {
    width: 17px;
    height: 17px;
  }
}

.selectedDate {
  width: 100%;
  input {
    color: $black;
    width: 80%;
  }
}

div.icon {
  margin-right: 20px;
}
