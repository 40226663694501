@import "../../assets/stylesheets/variables";
div.input {
  border: 1px solid $table-inner-border;
  width: 259px;

  font-size: 16px;
  border-radius: 8px;
  input {
    padding-left: 17px;
    padding-right: 8px;
  }
}
