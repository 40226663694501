@import "../../assets/stylesheets/variables";
.dropdown {
  min-width: 250px;
  text-align: center;
}

div.withoutPadding {
  padding-right: 0 !important;
}

.tooltipPlacementTop {
  top: -10px;
}

.headerStyles {
  min-width: 60%;
}
