@import "../../assets/stylesheets/variables";

p.title-text {
  font-size: 18px;
  font-weight: 300;
}

p.text {
  color: $header-text;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: normal;
  font-weight: 100;
  padding-bottom: 1em;
}
