@import "../../assets/stylesheets/variables";

.section {
  width: 50%;
}

div.list {
  padding: 0 1em;
  height: 295px;
  overflow: auto;
}

div.listItem {
  border-radius: 5px;
  border: solid 1px #dbdce3;
  opacity: 0.7;
  &:hover {
    opacity: 0.8;
  }
}
div.itemColor {
  background-color: $warning;
  &:hover {
    background-color: $warning;
  }
}

div.removeItem {
  background-color: $transfer-add-item;
  opacity: 1;
  &:hover {
    background-color: $transfer-add-item;
    opacity: 0.7;
  }
}

.itemContainer {
  padding: 0.5em 0;
}

.sideContainer {
  height: 360px;
}

p.headerText {
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
}

.headerContainer {
  display: flex;
  padding: 1em;
  justify-content: space-between;
}

p.headerButton {
  font-family: $pluto;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
}

.itemText {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $white;
  text-transform: uppercase;
}

.removeItemText {
  color: #707090;
}

.buttonContainer {
  cursor: pointer;
  pointer-events: none;
}

.icon g {
  fill: $expansion-panel;
}
