.pagination {
  margin-top: 20px;
  & > div {
    flex-direction: row-reverse;
    padding: 0;
  }
}

div.paginationSpacer {
  flex-basis: 0;
}
