@import "../../assets/stylesheets/variables";

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 14px;
  padding-left: 14px;
  border-right: 1px solid $table-border;
  height: 50px;
}

.label {
  display: block;
  color: $user-input;
  font-size: 14px;
  padding-left: 10px;
}

.required {
  color: $ui-primary;
  padding-left: 2px;
  width: 10px;
}

div.adornment {
  border-right: 1px solid $table-border;
  padding-right: 10px;
  margin-right: 0;
  height: 50px;
  max-height: 50px;
}

div.without-border {
  border-right: none;
  padding-right: 0;
}

div.avatar {
  width: 37px;
  height: 37px;
}

.icon {
  border: 1px solid $ui-secondary;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 50%;
    height: 50%;
  }
}

div.meta-error {
  border-right-color: $warning;
}
