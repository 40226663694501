@import "../../../../assets/stylesheets/variables";

thead.tableHead {
  background-attachment: fixed;
}

th.cell {
  color: $white;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  font-weight: 100;
  letter-spacing: normal;
  border: none;
  &:first-child {
    width: 7%;
  }
  &:nth-child(2) {
    width: 20%;
  }
  &:nth-child(3) {
    width: 20%;
  }
  &:nth-child(4) {
    width: 38%;
  }
  &:last-child {
    width: 15%;
  }
  span {
    color: $white;
    &:hover {
      color: $white;
    }
    &:focus {
      color: $white;
    }
  }
}
