@import "../../assets/stylesheets/variables";

span.container {
  display: block;
}

span.badge {
  color: $white;
  background: $button-gradient;
}
