.tools-container {
  svg {
    width: 21px;
    height: 21px;
  }
  a,
  button {
    padding: 7px;
  }
}
