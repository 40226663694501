@import "../../assets/stylesheets/variables";

div.popper {
  z-index: 1300;
  .tooltip {
    padding: 10px 15px;
    margin: 0;
    background-color: $white;
    border-radius: 5px;
    border: 2px solid $ui-primary;
    font-size: 13px;
    color: $ui-primary;
    position: relative;
    text-align: center;
    white-space: pre-wrap;
    &:after {
      position: absolute;
      content: " ";
      display: block;
      background-color: $white;
      left: 50%;
      transform: translateX(-50%) translateY(4px) rotate(45deg)
        skew(345deg, 345deg);
      width: 15px;
      height: 15px;
      border-right: 2px solid $ui-primary;
      border-bottom: 2px solid $ui-primary;
    }
  }

  .tooltipPlacementBottom {
    top: -16px;
    &:after {
      transform: translateX(-50%) translateY(-9px) rotate(225deg)
        skew(345deg, 345deg);
      top: 0;
    }
  }
}

.tooltipPlacementTop {
  top: 10px !important;
}
