@import "../../assets/stylesheets/variables";

div.container {
  padding-left: 33px;
  padding-right: 70px;
}

span.primary {
  color: $white;
  font-size: 18px;
  font-weight: 100;
  letter-spacing: normal;
  opacity: 0.6;
}

.arrow-up {
  opacity: 0.4;
  transform: rotate(180deg);
}

.arrow-down {
  opacity: 0.4;
}
