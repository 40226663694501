@import "../../../assets/stylesheets/variables";

div.content-padding {
  padding: 0 20px !important;
}

div.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 29px;
}
