@import "../../assets/stylesheets/variables";

a.list-item,
div.list-item {
  color: $white;
  padding-left: 33px;
  margin-bottom: 20px;
  div {
    margin: 0;
  }
}

.list-text {
  span {
    font-weight: 100;
    font-size: 18px;
  }
}
