@import "../../../../assets/stylesheets/variables";

.container {
  margin-top: 15px;
}
.profile-container {
  @include small {
    flex-direction: column;
  }
  @include medium {
    padding: 0 30px 20px 30px;
    flex-direction: row;
    justify-content: space-between;
  }
}

div.role-container {
  padding-left: 30px;
  padding-top: 58px;
  justify-content: space-between;
}

.role-title {
  font-weight: normal;
  font-size: 16px;
  color: $main-font;
}

div.input {
  width: 100%;
  margin-top: 3%;
  @include small {
    margin-top: 2%;
  }
  @include medium {
    margin-top: 23px;
    width: 48%;
  }
}

.label {
  color: $user-input;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 100;
}
