@import "../../assets/stylesheets/variables";

div.extra_small {
  width: 350px;
}

div.small {
  width: 400px;
}

div.medium {
  max-width: 483px;
  width: 483px;
}

div.large {
  max-width: 625px;
  width: 625px;
}

div.extra-large {
  width: 890px;
  max-width: 890px;
}

div.container {
  min-width: 300px;
  padding: 16px;
  @include small {
    padding: 26px 26px;
  }

  div.title {
    font-size: 16.7px;
    line-height: 1.38;
    letter-spacing: 0.35px;
    text-align: center;
    text-transform: uppercase;
    opacity: 0.5;
    text-align: left;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 300;
    color: #000000;
    padding: 0 0 20px;
  }

  div.warning {
    color: $warning;
    text-align: center;
    text-transform: uppercase;
    opacity: 0.5;
    font-family: PlutoSansDPD;
    font-size: 16.7px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.35px;
    text-align: center;
  }
}

button.disabled-button {
  background: none;
  background-color: $ui-secondary !important;
  span {
    color: $white;
  }
}

.button {
  width: 108px;
  span {
    font-size: 14px;
  }
}

div.button-container {
  justify-content: space-between;
  padding: 0;
  button {
    font-size: 13px;
    min-width: 118px;
    padding: 10px;
    white-space: nowrap;
  }
  button:not(:first-child) {
    margin-left: 25px;
  }
}

div.right {
  justify-content: flex-end;
}

div.center {
  justify-content: center;
}

div.left {
  justify-content: flex-start;
}

div.contentText {
  font-size: 18px;
  margin-bottom: 10px;
  padding: 0;
  p {
    color: $filter;
    font-size: 18px;
    text-align: center;
    line-height: 1.38;
    letter-spacing: 0.35px;
    white-space: pre-line;
  }
}

div.contentText:first-child {
  padding-top: 0;
}

.noteContainer {
  padding: 7px 0;
  p.noteContent {
    font-size: 14px;
    color: $filter;
    letter-spacing: 0.29px;
    text-align: center;
    font-weight: 100;
    line-height: 1.5;
  }

  .noteTitle {
    font-weight: normal;
  }
}

p.footerWarning {
  text-align: center;
  font-size: 15px;
  color: $warning;
  letter-spacing: normal;
  padding-top: 2%;
  line-height: normal;
  font-weight: normal;
}

.footerWarningContainer {
  height: 30px;
}
