@import "../../assets/stylesheets/variables";

.container {
  padding: 8px 0;
  max-height: 250px;
  overflow-y: auto;
}

.warning {
  padding-top: 15px;
  color: $warning;
  font-size: 15px;
  line-height: normal;
}
