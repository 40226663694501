div.checkbox-container {
  margin-top: 36px;
  margin-bottom: 27px;
}
.switch {
  width: 24%;
}

.options {
  padding-left: 30px;
}
