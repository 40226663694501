@import "../../assets/stylesheets/variables";

div.input {
  fieldset {
    border-radius: 7px;
    border-color: $table-border !important;
    border-width: 1px;
  }
  input {
    height: 51px;
    padding: 0;
    padding-left: 10px;
    width: auto;
    color: $black;
    font-size: 15px;
    flex-grow: 1;
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 40px $white inset !important;
    }
  }
}

.disabled {
  opacity: $disable-opacity;
}

.withUpperCase {
  input {
    text-transform: uppercase;
  }
}

.without-border {
  fieldset {
    border: none;
  }
}

div.adornedEnd {
  padding-right: 0;
}

div.adornedStart {
  padding-left: 0;
}

div.meta-error {
  fieldset {
    border-color: $warning !important;
    border-width: 1px !important;
  }
}
