@font-face {
  font-family: "PlutoSansDPD";
  font-weight: 100;
  src: local("PlutoSansDPDLight"), url("../fonts/PlutoSansDPDLight.otf");
}

@font-face {
  font-family: "PlutoSansDPD";
  font-weight: 100;
  font-stretch: condensed;
  src: local("PlutoSansDPDExtraLight"),
    url("../fonts/PlutoSansDPDExtraLight.otf");
}

@font-face {
  font-family: "PlutoSansDPD";
  font-stretch: condensed;
  src: local("PlutoSansDPDThin"), url("../fonts/PlutoSansDPDThin.otf");
}

@font-face {
  font-family: "PlutoSansDPD";
  font-weight: bold;
  src: local("PlutoSansBold"), url("../fonts/PlutoSansBold.otf");
}

@font-face {
  font-family: "PlutoSansDPD";
  font-weight: normal;
  src: local("PlutoSansDPDRegular"), url("../fonts/PlutoSansDPDRegular.otf");
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/RobotoRegular.woff");
  font-weight: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/RobotoLight.woff");
  font-weight: 100;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/RobotoBold.woff");
  font-weight: bold;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/RobotoMedium.woff");
  font-weight: 500;
}
