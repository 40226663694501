@import "../../../../assets/stylesheets/variables";

.info-value {
  font-size: 15px;
  padding: 14px 8px 0;
  color: $black;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.info-label {
  color: $user-input;
  font-size: 15px;
  text-transform: capitalize;
  padding: 14px 8px 0;
}

.select-container {
  width: 100%;
}

div.select-menu-container {
  box-shadow: none;
  border: 1px solid $table-border;
  max-height: 315px;
  max-width: min-content;

  ul {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.view-select {
  justify-content: flex-start;
  padding-left: 14px;
  width: 100%;
}

.table-cell {
  border-right: none !important;
  font-weight: normal !important;
  color: $black !important;

  &:first-child {
    text-decoration: none !important;
    text-align: center !important;
    cursor: default !important;
  }
  &:last-child {
    width: 45% !important;
  }
}

.table-container {
  padding: 0 10px;
  outline: none;
}
