@import "../../../assets/stylesheets/variables";

.contentWrapper {
  padding: 0 15px 25px;

  @include screenResolution1x5 {
    padding: 4%;
  }
}

div.titleStyles {
  padding-left: 14px !important;
}

.passwordIndicator {
  width: 84%;
  padding-top: 10px;
  padding-left: 5px;
  height: 36px;
}
.adornmentIcon {
  svg {
    width: 60%;
    height: 60%;
  }
}

div.field {
  width: 48%;
  padding-bottom: 23px;
}

div.password {
  width: 48%;
}

.warning {
  padding-bottom: 90px;
  padding-left: 16px;
}

div.newPasswordContainer {
  padding: 35px 0 0;
}

div.newPassword {
  width: 86%;
}
