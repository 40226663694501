@import "../../../../assets/stylesheets/variables";

div.container {
  width: 100%;
}
div.account-number {
  width: 100%;
}

div.profile-item {
  width: 48%;
}
