@import "../../../assets/stylesheets/variables";

div.contentText {
  font-size: 18px;
  padding: 16px;
  margin-bottom: 28px;
  p {
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-size: 18px;
    white-space: pre-line;
    font-family: Roboto;
    font-size: 15.3px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: left;
    letter-spacing: normal;
    color: #000000;
    margin: 0;
  }
}

div.change-message {
  border-radius: 8px;
  border: solid 1px #ededed;
  padding: 16px;
  margin-bottom: 28px;
  p {
    font-family: Roboto;
    font-size: 15.3px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: left;
    letter-spacing: normal;
    color: #000000;
    margin: 0;
  }
}
